<template>
  <v-container v-if="!Loading" id="users-table" fluid tag="section">
    <v-form
      @submit.stop.prevent="get_users_by_page"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row v-if="filter" justify="center" class="mt-6">
        <v-col cols="12" md="4">
          <v-text-field
            dense
            outlined
            :label="`${$t('name')}/${$t('email')}`"
            v-model="name_filter"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            outlined
            dense
            :label="$t('order_by')"
            :items="FilterOrderOptions"
            v-model="filter_order_selected"
            item-text="text"
            item-value="index"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            outlined
            dense
            :label="$t('type')"
            :items="filter_order_type_options"
            v-model="filter_order_type_selected"
            item-text="text"
            item-value="value"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            outlined
            dense
            :label="$t('selected_portfolio')"
            :items="portfolioOptions"
            v-model="portfolioSelected"
            item-text="number"
            item-value="id"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            outlined
            dense
            :label="$t('created_user_exists')"
            :items="filter_options"
            v-model="createdUserFilter"
            item-text="text"
            item-value="value"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            outlined
            dense
            :label="$t('completed_second_register')"
            :items="filter_options"
            v-model="secondRegisterFilter"
            item-text="text"
            item-value="value"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            outlined
            dense
            :label="$t('request_pending_approval')"
            :items="filter_options"
            v-model="requestPendingFilter"
            item-text="text"
            item-value="value"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <v-btn class="mx-3 dxa-btn-primary" type="submit">{{
            $t("apply")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">{{ $t("name") }}</th>
          <th class="text-center">{{ $t("email") }}</th>
          <th class="text-center">{{ $t("invest_value") }}</th>
          <th class="text-center">{{ $t("selected_portfolio") }}</th>
          <th class="text-center">{{ $t("created_user_exists") }}</th>
          <th class="text-center">{{ $t("last_login") }}</th>
          <th class="text-center">{{ $t("completed_second_register") }}</th>
          <th class="text-center">{{ $t("request_pending_approval") }}</th>
          <th class="text-center">{{ $t("options") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, index) in Users" :key="index">
          <td class="text-center">{{ gs.show_full_name(user) }}</td>
          <td class="text-center">{{ user.Email }}</td>
          <td class="text-center">
            {{ gs.format_to_currency(user.InvestValue) }}
          </td>
          <td class="text-center">
            {{ user.SelectedPortfolioId ? user.SelectedPortfolio.Number : "-" }}
          </td>
          <td class="text-center">
            {{ user.CreatedUser ? $t("yes") : $t("no") }}
          </td>
          <td class="text-center">
            {{
              user.LastLoginDate
                ? gs.convert_date(user.LastLoginDate, true)
                : "-"
            }}
          </td>
          <td class="text-center">
            {{
              user.CreatedUser
                ? user.CompletedSecondRegister
                  ? $t("yes")
                  : $t("no")
                : "-"
            }}
          </td>
          <td class="text-center">
            {{
              user.CreatedUser
                ? user.RequestPending
                  ? $t("yes")
                  : $t("no")
                : "-"
            }}
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 primary"
              @click="add_signature_notification(user)"
              min-width="0"
              small
            >
              <v-icon small>mdi-cash-plus</v-icon>
            </v-btn>
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_user(user)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(user)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      color="primary"
      @input="changePage"
      :length="Pager.TotalPages"
      v-model="page"
    >
    </v-pagination>
    <v-dialog v-if="dialog" v-model="dialog">
      <AddSignatureNotification
        :User="user"
        @close="reset_request_dialog"
        @update="reset_request_dialog"
      ></AddSignatureNotification>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name + ' ' + delete_obj.LastName"
        @close="delete_dialog = false"
        @delete="delete_user"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import AddSignatureNotification from "@/views/pages/signatureNotifications/form/AddSignatureNotification";
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";

export default {
  name: "AlphaInvestorFilterCRUD",

  components: {
    DeleteConfirmationModal,
    AddSignatureNotification,
  },
  props: {
    Loading: Boolean,
    Users: Array,
    Pager: Object,
    FilterOrderOptions: Array,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    filter: true,
    page: 1,
    name_filter: "",
    valid: true,
    lazy: false,
    filter_order_selected: -1,
    filter_order_type_selected: false,
    delete_obj: null,
    delete_dialog: false,
    dialog: false,
    user: null,
    portfolioOptions: [],
    portfolioSelected: null,
    createdUserFilter: -1,
    secondRegisterFilter: -1,
    requestPendingFilter: -1,
  }),
  async created() {
    await this.get_portfolio_options();
  },
  computed: {
    filter_options() {
      return [
        {
          value: -1,
          text: "-",
        },
        {
          value: 0,
          text: this.$t("no"),
        },
        {
          value: 1,
          text: this.$t("yes"),
        },
      ];
    },
    filter_order_type_options() {
      return [
        {
          value: false,
          text: this.$t("ascending"),
        },
        {
          value: true,
          text: this.$t("descending"),
        },
      ];
    },
  },
  methods: {
    async get_portfolio_options() {
      this.portfolioLoading = true;
      var noneSelected = {
        id: null,
        number: "-",
      };
      this.portfolioOptions = [noneSelected];
      await this.apiService
        .getRequest("alphaportfolio/list-all")
        .then((result) => {
          this.portfolioOptions = this.portfolioOptions.concat(result.content);
        })
        .catch((err) => {});
      this.portfolioLoading = false;
    },
    get_users_by_page() {
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = {
        Page: page,
        NameFilter: this.name_filter,
        Order: this.filter_order_selected,
        Descending: this.filter_order_type_selected,
        SelectedPortfolio: this.portfolioSelected,
        CreatedUser: this.createdUserFilter,
        CompletedSecondRegister: this.secondRegisterFilter,
        RequestPending: this.requestPendingFilter,
      };
      this.$emit("load_more", params);
    },
    reset_request_dialog() {
      this.dialog = false;
      this.user = null;
    },
    add_signature_notification(user) {
      this.dialog = true;
      this.user = user;
    },
    changePage() {
      this.$router.replace({ query: { page: this.page } });
      this.get_users_by_page();
    },
    open_delete_dialog: function (user) {
      this.delete_dialog = true;
      this.delete_obj = user;
    },
    edit_user(user) {
      if (!user.CreatedUser) {
        this.$router.push(`/alpha-investors/edit/${user.Id}`);
      } else {
        this.$router.push({
          path: `/users/edit/${user.Id}`,
          params: { user_id: user.Id },
        });
      }
    },
    async delete_user(user) {
      var index = this.Users.indexOf(user);
      this.Users.splice(index, 1);
      this.delete_dialog = false;
      var url = user.CreatedUser ? "user" : "alphainvestor";
      this.apiService
        .deleteRequest(`${url}/delete`, `"${user.Id}"`)
        .then((resp) => {})
        .catch((error) => {});
    },
  },
};
</script>
