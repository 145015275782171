<template>
  <v-container id="users-table" fluid tag="section">
    <base-material-card
      icon="mdi-account"
      inline
      color="primary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:heading><v-icon large>mdi-account</v-icon></template>
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("alpha_investor", 2) }}</b>
        </h1></template
      >

      <v-btn
        color="primary"
        rounded
        @click="download_allocations_report"
        style="right: 65px"
        :loading="reportDownloading"
        absolute
        small
        fab
        top
        right
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <AlphaInvestorFilterCRUD
        :Users="users"
        :Pager="pager"
        :Loading="loading"
        :FilterOrderOptions="filter_order_options"
        @load_more="get_users_by_page"
      />
    </base-material-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import AlphaInvestorFilterCRUD from "@/components/dxa/filterCrud/AlphaInvestorFilterCRUD";
import moment from "moment";

export default {
  name: "AlphaAnalystTable",

  components: {
    AlphaInvestorFilterCRUD,
  },

  data: () => ({
    apiService: new ApiService(),
    users: [],
    pager: null,
    reportDownloading: false,
    loading: false,
  }),
  async created() {
    this.get_users_by_page({
      NameFilter: null,
      Page: 1,
      Order: -1,
      CreatedUser: -1,
      CompletedSecondRegister: -1,
      RequestPending: -1,
    });
  },
  computed: {
    filter_order_options() {
      return [
        {
          index: -1,
          text: this.$t("name"),
        },
        {
          index: 0,
          text: this.$t("email"),
        },
        {
          index: 1,
          text: this.$t("register_date"),
        },
        {
          index: 2,
          text: this.$t("invest_value"),
        },
        {
          index: 3,
          text: this.$t("last_login"),
        },
      ];
    },
  },
  methods: {
    async download_allocations_report() {
      this.reportDownloading = true;
      this.apiService
        .getRequest("investor/users-report")
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute(
            "download",
            `Alpha Investors Report - ${moment().format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.reportDownloading = false;
        })
        .catch((error) => {
          this.reportDownloading = false;
        });
    },
    get_users_by_page(params) {
      this.loading = true;
      this.apiService
        .getRequest("investor/alpha-list/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.pager = json.Pager;
          this.users = json.Users;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
